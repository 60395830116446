import { useState, useEffect } from 'react'
import { postContactForm } from '../../api/post'

export const useInput=(validate)=>{
    const [inputs,setInput] = useState({});
    const [errors,setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    useEffect(()=>{
        if (Object.keys(errors).length === 0 && isSubmitting) {
             handleSubmitForm();
            }
        },[errors])
    const handleSubmit =(event)=>{
        if(event){
        event.preventDefault();
        }
        setIsSubmitting(true);        
        setErrors(validate(inputs));
    }
    const handleChange =(event)=>{
        event.persist();
        setInput(inputs=>({...inputs,[event.target.name]: event.target.value}));
    }
    const handleSubmitForm = async ()=>{
        setLoading(true);
        try {
          let data = await postContactForm(inputs.firstname,inputs.lastname,inputs.email,inputs.mobile,inputs.inquiry);   
          if (data){
            setInput({...inputs,firstname:"",lastname:"",email:"",mobile:"",inquiry:""});
            alert("You've successfully submitted the form!");       
          }
          setLoading(false);
        } catch (e) {
            setInput({...inputs,firstname:"",lastname:"",email:"",mobile:"",inquiry:""});
            return alert("Please try again!");    
        }
    }
    return{
        inputs,
        isLoading,
        errors,
        handleChange,
        handleSubmit,   
    }
}