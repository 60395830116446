export default function validate (inputs){
    let errors = {};
	if (!inputs.firstname) {
		errors.firstname = 'First name is required';
    }
	if (!inputs.lastname) {
		errors.lastname = 'Last name is required';
	}
	if (!inputs.email) {
		errors.email = 'Email address is required';
	}
	else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
		errors.email = 'Email address is invalid';
	}
    if (!inputs.mobile ){
		errors.mobile = 'Mobile number is required';
	}
    else if(!/(\+?\d{2}?\s?\d{3}\s?\d{3}\s?\d{4})|([0]\d{3}\s?\d{3}\s?\d{4})/g.test(inputs.mobile)){
		errors.mobile= "Mobile number is invalid";
    }
    if (!inputs.inquiry) {
		errors.inquiry = 'Inquiry is required';
	}
	return errors;
}
