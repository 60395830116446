import React from 'react';
import '../layout.css';

const Input = ({ name,value_input,onChangeValue}) => {
	switch (name) {
		case 'firstname':
            return <React.Fragment>
                    <input 
                    className='cortex-contact-input' 
                    name="firstname"
                    type="email"
                    onChange={onChangeValue}
                    value={value_input.firstname}
                     />
                     </React.Fragment>
		case 'lastname':
            return <React.Fragment>
                    <input 
                    className='cortex-contact-input' 
                    name="lastname"
                    onChange={onChangeValue}
                    value={value_input.lastname }
                    />
                    </React.Fragment> 
		case 'email':
            return  <React.Fragment>
                    <input 
                    className='cortex-contact-input' 
                    name="email"
                    onChange={onChangeValue}
                    value={value_input.email }
                    />
                   </React.Fragment> 
		case 'mobile':
            return  <React.Fragment>
                   <input 
                    className='cortex-contact-input' 
                    name="mobile"
                    onChange={onChangeValue}
                    value={value_input.mobile}                 
                    />
                    </React.Fragment>
		case 'inquiry':
            return  <React.Fragment>
                    <input
                     className='cortex-contact-input-inq' 
                     name="inquiry"
                    onChange={onChangeValue}
                     value={value_input.inquiry }          
                     />
                     </React.Fragment>

		default:
			return name;
	}
};

export default Input;
