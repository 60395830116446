const url = 'https://wnb5tbcsw7.execute-api.us-east-1.amazonaws.com/v4'


export async function postContactForm (fname,lname,email,mobile,inquiry){
    const options={ 
        method:'POST',
        headers: {'Accept':'application/json','Content-Type':'application/json'},
        body:JSON.stringify({"fname":fname,"lname":lname,"email":email, "mobile":mobile,"inquiry":inquiry})
    }
    const response= await fetch(`${url}`,options)
    const data = await response.json();
    // const status = await response.status;
    // console.log("Nmode Result:",status);
    // console.log(data)
    return {data};
}