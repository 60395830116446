import React from 'react';
import Layout from '../components/layout';
import ContentAllExceptIndex from '../components/content-all-except-index';
import '../components/layout.css';
import ContentSnd from '../components/content-snd';
import SEO from '../components/seo';
import ContentThd from '../components/content-thd';
import Footer from '../components/footer';
import Input from '../components/input/Input';
import  validate  from '../components/input/InputValidationRules';
import { useInput } from '../components/input/useInput';

const Contact = () => {
	const { 
        handleSubmit,
		errors,
		handleChange,
		inputs,
		isLoading
		} = useInput(validate);
	return (
		<Layout>
			<SEO title='Cortex - Contacts' />
			<ContentAllExceptIndex title="contacts">
				<div className='cortex-home-content-nd-contact'>
					Contact us
					<div className='cortex-all-content-except-index-p-contact'>
						<p>Let’s talk. Let’s brainstorm. Let’s Co-Create and find solutions. Let’s work together! </p>
					</div>
				</div>
			</ContentAllExceptIndex>
			<ContentSnd>
				<div className='cortex-home-content-snd'>
					<div className='cortex-home-content-snd-p'>
						<div className='cortex-home-content-title-contact'>Connect with us </div>
						<p className='cortex-home-snd-p-contact'>
							Let's talk. Let's brainstorm. Let's Co-Create and find solutions. Let's work together.
						</p>
					</div>
				</div>
				<div className='cortex-home-content-form'>
					<div className='cortex-contact-content-ul'>
						<div className='cortex-contact-content-li'>
							<p className='cortex-contact-content-li-p'>First name </p>
							<Input name='firstname' value_input={inputs} onChangeValue={handleChange} />
							<p id="cortex-error-message">{errors.firstname}</p>
						</div>
						<div className='cortex-contact-content-li'>
							<p className='cortex-contact-content-li-p'>Last name</p>
							<Input  value_input={inputs} name='lastname' onChangeValue={handleChange}/>
							<p id="cortex-error-message">{errors.lastname}</p>
						</div>
						<div className='cortex-contact-content-li'>
							<p className='cortex-contact-content-li-p'>Email</p>
							<Input  value_input={inputs} name='email' onChangeValue={handleChange} />
							<p id="cortex-error-message">{errors.email}</p>
						</div>
						<div className='cortex-contact-content-li'>
							<p className='cortex-contact-content-li-p'>Mobile Number </p>
							<Input  value_input={inputs} name='mobile' onChangeValue={handleChange}/>
							<p id="cortex-error-message">{errors.mobile}</p>
						</div>
						<div className='cortex-contact-content-li'>
							<p className='cortex-contact-content-li-p'>Inquiry </p>
							<Input  value_input={inputs} name='inquiry' onChangeValue={handleChange}/>
							<p id="cortex-error-message">{errors.inquiry}</p>
						</div>
						<div className='cortex-contact-content-li' onClick={(event)=>handleSubmit(event)} >
							{isLoading?
							<div className='cortex-home-content-button'>Sending..</div>:
							<div className='cortex-home-content-button'>Submit</div>
							}
						</div>
					</div>
				</div>
			</ContentSnd>
			<ContentThd>
				<div className='cortex-home-content-snd'>
					<div className='cortex-home-content-snd-p'>
						<div className='cortex-home-content-title-contact'>Our Headquarters</div>
						<p className='cortex-home-snd-p-contact'>
							We are located at 5F Asian Star Building ASEAN Dr, Alabang, Muntinlupa, 1781 Metro Manila.
						</p>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
					<iframe
						title="cortexMapLocation"
						src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1932.0628380209228!2d121.03742933891849!3d14.419917932120615!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d0343f027647%3A0x69da9a94c226a7ca!2sAsian%20Star%20Building%2C%20ASEAN%20Dr%2C%20Alabang%2C%20Muntinlupa%2C%201781%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1567475408240!5m2!1sen!2sph'
						style={{ width: '100%', height: '60vh', border: 'none' }}
					/>
				</div>
			</ContentThd>
			<Footer />
		</Layout>
	);
};
export default Contact;
